.ReactTable .rt-tbody .rt-tr-group {
   min-height: 1.2em;
}
.ReactTable .rt-tbody .rt-tr-group:hover {
   background: #e8d905;
}
.ReactTable.-striped .rt-tr.-odd {
   background-color: rgba(0, 139, 101, 0.07);
   font-size: 1.1em;
   background: #d3d3d3;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
   box-shadow: inset 0 -3px 0 0 #e8d905;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
   box-shadow: inset 0 3px 0 0 #e8d905;
}

.rdt_TableHeader {
   display: none !important;
}


.rdt_TableCol_Sortable {
   font-size: 1.12em
}
[data-tag="___react-data-table-allow-propagation___"] {
   font-size: 1.05em
}
.rdt_Table {
   height: auto !important
}

.rdt_TableBody {
   width: 100%;
}
.rdt_TableFooter {
   width: 93%;
}
@media screen and (max-width: 600px){
   .rdt_TableBody {
      width: 99vw;
      overflow-x: scroll;
   }
}